/* You can add global styles to this file, and also import other style files */
@import 'styles/tools/reset';
@import 'styles/fonts/gotham/gotham';
@import 'src/styles/shared';
@import 'src/styles/components/spinner';
@import 'src/styles/components/blackout';
@import 'src/styles/components/switch-toggle';

html {
  font-family: Gotham, 'Avenir Next', 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  color: $color-dark-blue;
}

h1 {
  font-size: 3.75rem;
  // line-height: 1.17;
}

h2 {
  font-size: 1.625rem;
}

.section-header {
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.09;
  color: $color-dark-blue;
  margin-bottom: 1.5rem;
}

button {
  color: $color-dark-blue;
}

input,
select {
  color: $color-dark-gray;
}

button {
  cursor: pointer;
}

input:disabled {
  opacity: 1;
}

input,
input:disabled,
textarea,
textarea:disabled {
  &::-webkit-input-placeholder, /* WebKit, Blink, Edge */
  &:-moz-placeholder, /* Mozilla Firefox 4 to 18 */
  &:-ms-input-placeholder,  /* Internet Explorer 10-11 */
  &::placeholder {
    color: $color-placeholder;
    opacity: 1;
  }
}

a {
  color: $color-light-blue;
}

.strike {
  text-decoration: line-through;
}

.w-100 {
  width: 100%;
}

/* COMPONENTS */

// .app-input, .app-input:-webkit-autofill {
//   padding-left: 4px;
//   border-bottom: 1px solid $color-dark-blue;
//   // background: linear-gradient(to bottom, transparent 95%, $color-brand-1 95%) no-repeat;
//   // background-size: 0% 100%;
//   // transition: background-size 0.2s ease;
//   -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
//   transition: box-shadow 0.2s ease;
//   margin-right: 1rem;
//   margin-bottom: 1.5rem;

//   width: 100%;
//   line-height: 2rem;
//   box-shadow: 0px 0px 0px 0px $color-brand-1;

//   &:focus {
//     border-bottom: 1px solid $color-brand-1;
//     // background-size: 100% 100%;
//     box-shadow: 0px 2px 2px -2px $color-brand-1;
//   }

//   &.ng-invalid.ng-touched {
//     color: $color-error;
//     background:  url('/assets/icons/error-input-icon.svg') right center no-repeat;
//   }
// }

.app-checkbox {
  font-weight: 400;
  font-size: 14px;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    & + .app-checkbox-icon {
      display: inline-block;
      vertical-align: middle;
      line-height: 1;

      &::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid $color-disabled;
        border-radius: 0.25em;
        margin-right: 0.9em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 120% 120%;
      }
    }

    &:checked + .app-checkbox-icon::before {
      border-color: $color-brand-1;
      background-color: $color-brand-1;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23fff' d='M 18.567 9.575 L 17.07 11.19 L 12.582 16.035 L 12.375 16.258 L 12.169 16.481 L 11.962 16.704 L 11.756 16.927 L 11.069 16.327 L 10.841 16.126 L 9.837 15.247 L 8.833 14.367 L 7.829 13.488 L 6.826 12.609 C 6.696 12.496 6.595 12.361 6.525 12.215 C 6.454 12.069 6.413 11.912 6.4030000000000005 11.752 C 6.392 11.592 6.412 11.43 6.463 11.276 C 6.514 11.122 6.596 10.975 6.71 10.845 C 6.823 10.715 6.958 10.615 7.104 10.544 C 7.25 10.473 7.407 10.433 7.567 10.422 C 7.726 10.412 7.888 10.431000000000001 8.043 10.482 C 8.197 10.533 8.344 10.615 8.474 10.729 L 11.574 13.445 L 12.863 12.053 L 14.153 10.66 L 16.733 7.876 C 16.85 7.75 16.987 7.652 17.135 7.586 C 17.283 7.519 17.442 7.482 17.602 7.476 C 17.762 7.469 17.923 7.494 18.076 7.549 C 18.228 7.604 18.372 7.691 18.499 7.808 C 18.625 7.926 18.722 8.063 18.789 8.211 C 18.856 8.359 18.893 8.518 18.899 8.677 C 18.906 8.837 18.881 8.998 18.826 9.151 C 18.771 9.303 18.685 9.448 18.567 9.574 L 18.567 9.575 Z'/%3e%3c/svg%3e");
      // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    &:disabled + .app-checkbox-icon::before {
      opacity: 0.5;
    }
  }
}

// widget
.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;

  &:hover {
    width: 256px !important;
  }
}

// button
.btn-primary,
.btn-secondary {
  border: 2px solid $color-brand-1;
  font-weight: 500;
  min-width: 240px;
  line-height: 36px;
  border-radius: 20px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-decoration: none;
  display: inline-block;
  transition: 0.3s;
  font-size: 16px;

  &:disabled {
    border-color: $color-disabled !important;
    cursor: not-allowed;
  }
}

.btn-primary {
  background: $color-brand-1;
  color: white;

  &:hover {
    border-color: darken($color-brand-1, 5);
    background-color: darken($color-brand-1, 5);
  }

  &:disabled {
    background: $color-disabled;
  }
}

.btn-secondary {
  border: 2px solid $color-brand-1;
  color: $color-brand-1;

  &:disabled {
    color: $color-disabled;
  }

  &:hover {
    background-color: darken(#fff, 3);
  }
}

.btn-primary,
.btn-secondary {
  margin-bottom: 1rem;
}

.btn-primary.small,
.btn-secondary.small {
  min-width: 140px;
  border-radius: 3px;
  font-weight: normal;
  font-size: 12px;
}

.btn-primary.force,
.btn-secondary.force {
  border: 2px solid $color-brand-1;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
}

.btn-primary.force {
  background: $color-brand-1;

  &:hover {
    border-color: darken($color-brand-1, 5);
    background-color: darken($color-brand-1, 5);
  }
}

.btn-secondary.force {
  border: 2px solid $color-brand-1;
  color: $color-brand-1;
}

input[type='checkbox'] {
  accent-color: $color-brand-1;
}

.input-wrapper {
  &.has-error {
    .invalid-icon {
      display: block;
    }

    .app-input {
      color: $color-error;
    }

    .input-error {
      display: block;
    }
  }

  .input-error,
  .input-validation-in-progress {
    color: $color-error;
    font-size: 0.875rem;
    padding-top: 0.5rem;
    line-height: 1.71;
    @include breakpoint-down(sm) {
      line-height: 1.2;
    }
  }

  .input-error {
    display: none;
  }
}

// margins

.ml-0 {
  margin-left: 0;
}

// breakpoints show/hide

.sm-show,
.md-show {
  display: none;
}

@include breakpoint-down(sm) {
  .sm-show {
    display: block;
  }

  .sm-hide {
    display: none;
  }

  .sm-fs16 {
    font-size: 16px !important;
  }
}

@include breakpoint-down(md) {
  .md-show {
    display: block;
  }

  .md-hide {
    display: none !important;
  }
}

@import 'styles/override/override';
